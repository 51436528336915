<template>
  <div>
    <div class="mbanner">
      <div class="wrapper">
        <div class="mbanner-hd">
          <div class="cn">
            <h2>创业培训</h2>
          </div>
          <div class="en">Entrepreneurship training</div>
        </div>
        <div class="mbanner-bd mbanner-bd1">
          创业培训赋能企业家，助力企业成长！以服务"大众创业、万众创新"为己任，<br />
          尊重创业家精神，汇聚各类创业要素，深入开展创业研究、<br />
          创业孵化、创业沙龙、创业培训、创业咨询、<br />
          对标访学等一些活动，持续推进多层次、<br />
          立体化的创新创业孵化课程及活动体系建设，<br />
          为广大创业者搭建一个深度学习、交流互鉴、拓展人脉、共谋发展的广阔舞台。
        </div>
      </div>
    </div>
    <div class="xstype">
      <div class="wrapper">
        <ul>
          <li>
            <div class="img"><img src="../../../assets/images/icon56.png" /></div>
            <h3>线上主题分享</h3>
          </li>
          <li>
            <div class="img"><img src="../../../assets/images/icon57.png" /></div>
            <h3>线下精英沙龙</h3>
          </li>
          <li>
            <div class="img"><img src="../../../assets/images/icon58.png" /></div>
            <h3>高端私董会</h3>
          </li>
          <li>
            <div class="img"><img src="../../../assets/images/icon59.png" /></div>
            <h3>企业诊所</h3>
          </li>
          <li>
            <div class="img"><img src="../../../assets/images/icon60.png" /></div>
            <h3>对标访学</h3>
          </li>
          <li>
            <div class="img"><img src="../../../assets/images/icon61.png" /></div>
            <h3>增长研修班</h3>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
</script>
<style scoped lang="scss">
.mbanner {
  background-image: url(../../../assets/images/mbanner08.jpg);
}
</style>
