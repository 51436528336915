<template>
  <div class="gdsdh-box">
    <div class="wrapper">
      <div class="mrow gdsdh">
        <div class="mtit1">
          <h2 class="cn">高端私董会</h2>
          <div class="en">High-end private board</div>
          <div class="line"></div>
        </div>
        <div class="gdsdh-txt">
          汇集跨行业的企业家的集体智慧，解决企业经营管理中比较复杂而有难以实现的问题。
        </div>
        <div class="gdsdh-list">
          <ul>
            <li>
              <div class="img"><img src="../../../assets/images/icon68.png" /></div>
              <h3>资源共享，团结互助</h3>
            </li>
            <li>
              <div class="img"><img src="../../../assets/images/icon69.png" /></div>
              <h3>兼容并包，求同存异</h3>
            </li>
            <li>
              <div class="img"><img src="../../../assets/images/icon70.png" /></div>
              <h3>崇尚节俭，平等分担</h3>
            </li>
            <li>
              <div class="img"><img src="../../../assets/images/icon71.png" /></div>
              <h3>坦诚相待，保守秘密</h3>
            </li>
          </ul>
        </div>
      </div>
      <div class="mline mline1"></div>

      <div class="mrow qyzs">
        <div class="mtit1">
          <h2 class="cn">企业诊所</h2>
          <div class="en">Corporate Clinic</div>
          <div class="line"></div>
        </div>
        <div class="qyzs-txt">
          专为中小企业精心打造“企业诊所”-微咨询，比如针对目标体系，诺亚从企业的“战略地图”、“实现路径”、“衡量标准”三个方面对企业的使命和目标进行诊断，<br />
          帮助企业厘清发展目标，进而合理分配资源、明确权责利，聚焦核心资源和优势，<br />
          保障企业经营目标的实现，其他咨询课题还有：阿米巴经营、组织管理体系、流程体系、薪酬激励体系、绩效管理体系等等。
        </div>
        <div class="qyzs-zl">
          <div class="qyzs-tit">调研诊断从内外部信息资料收集开始</div>
          <ul>
            <li>
              <div class="item">
                <div class="num"><img src="../../../assets/images/1.png" /></div>
                <div class="hd">
                  <img class="icon" src="../../../assets/images/icon72.png" />
                  <h3>项目准备</h3>
                </div>
                <div class="bd">
                  <p>
                    <span class="dot">●</span> 定制项目工作计划<br />
                    （滚动调整，每周一次）
                  </p>
                  <p><span class="dot">●</span> 确定访谈计划（日程与对象）</p>
                  <p><span class="dot">●</span> 访谈准备：预研、问题清单设计</p>
                  <p><span class="dot">●</span> 准备客户方资料需求清单</p>
                </div>
              </div>
              <div class="next"></div>
            </li>
            <li>
              <div class="item">
                <div class="num"><img src="../../../assets/images/2.png" /></div>
                <div class="hd">
                  <img class="icon" src="../../../assets/images/icon73.png" />
                  <h3>访谈、收集资料</h3>
                </div>
                <div class="bd">
                  <p><span class="dot">●</span> 访谈高层</p>
                  <p><span class="dot">●</span> 访谈主要管理与业务部门</p>
                  <p>
                    <span class="dot">●</span> 收集有关行业薪酬的数据及其他
                    相关资料
                  </p>
                  <p><span class="dot">●</span> 整理访谈纪要</p>
                </div>
              </div>
              <div class="next"></div>
            </li>
            <li>
              <div class="item">
                <div class="num"><img src="../../../assets/images/3.png" /></div>
                <div class="hd">
                  <img class="icon" src="../../../assets/images/icon74.png" />
                  <h3>问题分析</h3>
                </div>
                <div class="bd">
                  <p>
                    <span class="dot">●</span> 整理客户需求，<br />
                    确定工作范围和重点
                  </p>
                  <p><span class="dot">●</span> 发现、确认关键问题并深入分析</p>
                  <p>
                    <span class="dot">●</span> 目前现状与最佳实践和管理需求
                    之间的差距
                  </p>
                  <p><span class="dot">●</span> 确定改进的机会并突出重点</p>
                </div>
              </div>
              <div class="next"></div>
            </li>
            <li>
              <div class="item">
                <div class="num"><img src="../../../assets/images/4.png" /></div>
                <div class="hd">
                  <img class="icon" src="../../../assets/images/icon75.png" />
                  <h3>分组讨论</h3>
                </div>
                <div class="bd">
                  <p>
                    <span class="dot">●</span> 与客户 就收集到的资料进行
                    分组讨论，获取更准确信息
                  </p>
                  <p><span class="dot">●</span> 为下一阶段工作做准备</p>
                </div>
              </div>
              <div class="next"></div>
            </li>
          </ul>
        </div>
        <div class="qyzs-zlt">
          <div class="qyzs-tit">BSC战略图</div>
          <div class="img">
            <img src="../../../assets/images/img38.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { newRouter } from "@/libs/comm";
export default {
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    toRouter() {
      newRouter("apply");
    },
 
   
  
  },
};
</script>
<style scoped lang="scss"></style>
