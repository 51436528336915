<template>
  <div>
    <div class="online mrow mt-100">
      <div class="wrapper">
        <div class="mtit1">
          <h2 class="cn">线上主题分享</h2>
          <div class="en">Online theme sharing</div>
          <div class="line"></div>
        </div>
        <div class="online-txt">
          利用互联网、数字化及直播平台，同时作为混沌大学石家庄学习中心，为企业家传播创新理念，提升思维认知，助力每一企业家及组织成就不凡。<br />
          每年50+认识课每周上新，400+经典内容畅学，还可以进修高阶内容分：创学学科、哲科思维、创新商学。
        </div>
        <div class="online-qy">
          <div class="online-qy-tit">入学后更多专属权益</div>
          <ul>
            <li>
              <div class="online-bg">
                <img src="../../../assets/images/icon65.png" />
              </div>
              <div class="icon">
                <img src="../../../assets/images/icon62.png" />
              </div>
              <div class="info">
                <h3>创新学科</h3>
                <div class="txt">创新创业者的认知升级之旅</div>
              </div>
            </li>
            <li>
              <div class="online-bg">
                <img src="../../../assets/images/icon66.png" />
              </div>
              <div class="icon">
                <img src="../../../assets/images/icon63.png" />
              </div>
              <div class="info">
                <h3>哲科思维</h3>
                <div class="txt">探索思想背后的思想</div>
              </div>
            </li>
            <li>
              <div class="online-bg">
                <img src="../../../assets/images/icon67.png" />
              </div>
              <div class="icon">
                <img src="../../../assets/images/icon64.png" />
              </div>
              <div class="info">
                <h3>创新好课</h3>
                <div class="txt">平台往期400+好课</div>
              </div>
            </li>
          </ul>
        </div>
        <div class="mfullbtn">
          <a @click="toRouter('apply',{cn:'创业培训',en:'ENTREPRENEURSHIP TRAINING',type:3})"><img src="../../../assets/images/icon33.png" />在线咨询</a>
        </div>
      </div>
    </div>

    <div class="offline mrow">
      <div class="wrapper">
        <div class="mtit1">
          <h2 class="cn">线下精英沙龙</h2>
          <div class="en">Offline elite salon</div>
          <div class="line"></div>
        </div>
        <div class="offline-txt">
          搭建平台，缔结人脉、促进交流、共同发展，线下精英沙龙活动<br />
          为企业家创建的共学场
        </div>
        <div class="offline-list">
          <div class="swiper-container">
            <ul class="swiper-wrapper">
              <li class="swiper-slide">
                <a>
                  <img class="img"
                       src="../../../assets/images/img44.png" />
                  <h3>构建创新型组织</h3>
                </a>
              </li>
              <li class="swiper-slide">
                <a>
                  <img class="img"
                       src="../../../assets/images/img45.png" />
                  <h3>党建沙龙</h3>
                </a>
              </li>
              <li class="swiper-slide">
                <a>
                  <img class="img"
                       src="../../../assets/images/img46.png" />
                  <h3>营销沙龙</h3>
                </a>
              </li>
              <li class="swiper-slide">
                <a>
                  <img class="img"
                       src="../../../assets/images/img47.png" />
                  <h3>经营沙龙（沙漠掘金）</h3>
                </a>
              </li>
            </ul>
          </div>
          <div class="swiper-button-prev offline-prev"></div>
          <div class="swiper-button-next offline-next"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "@/libs/js/swiper.min";
export default {
  data () {
    return {};
  },
  mounted () {
    new Swiper(".offline-list .swiper-container", {
      slidesPerView: 4,
      spaceBetween: 30,
      loop: true,
      autoplayDisableOnInteraction: false,
      autoplay: 4000,
      nextButton: ".offline-next",
      prevButton: ".offline-prev",
      breakpoints: {
        1000: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
      },
    });
  },
  methods: {
    toRouter (routerName, item) {
      let query = {
        ...item
      };

      this.newRouter('/' + routerName, query)
    }
  },
};
</script>
<style scoped lang="scss"></style>
