<template>
  <div class="ysb mrow">
    <div class="wrapper">
      <div class="mtit1">
        <h2 class="cn">增长研修班</h2>
        <div class="en">Growth seminar</div>
        <div class="line"></div>
      </div>
      <div class="ysb-txt">
        赋能企业"增长"总裁研修班以服务"大众创业、万众创新"为己任，尊重创业家精神，<br />
        汇聚各类资源要素，采取多种形式赋能企业家，助力企业成长，<br />
        为广大企业家搭建一个深度学习、交流互鉴、拓展人脉、共谋发展的广阔舞台。
      </div>
      <div class="ysb-info">
        <h3 class="tit">课程体系设计</h3>
        <div class="img">
          <img src="../../../assets/images/img43.png" />
        </div>
      </div>
      <div class="ysb-list">
        <div class="swiper-container">
          <ul class="swiper-wrapper">
            <li class="swiper-slide">
              <div class="img">
                <img src="../../../assets/images/img60.png" />
              </div>
              <h3 class="name">阮老师</h3>
              <h4>企业顶层设计专家</h4>
              <div class="txt">
                毕业于中国政法大学，硕士研究生<br />
                北京优资律师事务所主任律师<br />
                工信部中小企业服务中心副秘书长<br />
                国之大铭知识产权事务所创始人
              </div>
            </li>
            <li class="swiper-slide">
              <div class="img">
                <img src="../../../assets/images/img61.png" />
              </div>
              <h3 class="name">苏老师</h3>
              <h4>企业战略与商业模式设计专家</h4>
              <div class="txt">
                英国利物浦大学工商管理博士<br />
                北京欧度技术咨询有限公司总经理<br />
                全国18所高校总裁班客座教授<br />
                38家企业战略发展顾问
              </div>
            </li>
            <li class="swiper-slide">
              <div class="img">
                <img src="../../../assets/images/img62.png" />
              </div>
              <h3 class="name">赵老师</h3>
              <h4>企业绩效管理专家</h4>
              <div class="txt">
                工分绩效激励体系/企业应用理论创编人<br />
                工分制（北京）咨询有点公司董事长<br />
                北京企业管理研修院副院长<br />
                北清智库《工分绩效》理论研究所所长
              </div>
            </li>
            <li class="swiper-slide">
              <div class="img">
                <img src="../../../assets/images/img63.png" />
              </div>
              <h3 class="name">石老师</h3>
              <h4>国内知名商业模式与投融资专家</h4>
              <div class="txt">
                国家科技部专家库专家评委<br />
                工信部全国领军人才项目专家<br />
                被评选为亚洲十大教育名师<br />
                创业黑马模式融合模块导师
              </div>
            </li>
          </ul>
        </div>
        <div class="swiper-button-prev ysb-prev"></div>
        <div class="swiper-button-next ysb-next"></div>
      </div>
      <div class="mfullbtn">
        <a @click="toRouter('apply',{cn:'入学咨询',en:'Admission consultation',type:2})"><img src="../../../assets/images/icon33.png" />入学咨询</a>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "@/libs/js/swiper.min";
export default {
  data () {
    return {
      list: [],
    };
  },
  mounted () {
    new Swiper(".ysb-list .swiper-container", {
      slidesPerView: 4,
      spaceBetween: 30,
      loop: true,
      autoplayDisableOnInteraction: false,
      autoplay: 4000,
      nextButton: ".ysb-next",
      prevButton: ".ysb-prev",
      breakpoints: {
        1000: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
      },
    });
  },
  methods: {
    toRouter (routerName, item) {
      let query = {
        ...item
      };

      this.newRouter('/' + routerName, query)
    }
  },
};
</script>
<style scoped lang="scss"></style>
