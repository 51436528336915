<template>
  <div class="dbfx mrow">
    <div class="wrapper">
      <div class="mtit1">
        <h2 class="cn">对标访学</h2>
        <div class="en">Benchmarking</div>
        <div class="line"></div>
      </div>
      <div class="dbfx-list">
        <div class="swiper-container">
          <ul class="swiper-wrapper">
            <li class="swiper-slide">
              <div class="img">
                <img src="../../../assets/images/img39.png" />
              </div>
              <div class="info">
                <div class="infohd">
                  <h3>阿里巴巴</h3>
                  <h4>行业：电子商务</h4>
                  <div class="txt">全球市值第五互联网科技企业</div>
                </div>
                <div class="infobd">
                  <strong class="fc-blue">09:00-09:30 </strong>
                  阿里文化体验：阿里巴巴西溪园区或滨江园区<br />
                  <strong class="fc-blue">09:30-12:00</strong>
                  培训课堂：《阿里巴巴企业文化与价值观》<br />
                  <strong class="fc-blue">12:00-13:30</strong>
                  午餐(阿里员工餐厅)及休息<br />
                  <strong class="fc-blue">13:30-17:00</strong>
                  培训课堂:《阿里文化与管理创新之道》<br />
                  (注：6个课程可选，可定制，1-5天）
                </div>
              </div>
            </li>
            <li class="swiper-slide">
              <div class="img">
                <img src="../../../assets/images/img40.png" />
              </div>
              <div class="info">
                <div class="infohd">
                  <h3>蚂蚁金服</h3>
                  <h4>行业：互联网金融</h4>
                  <div class="txt">普惠金融服务的创新型科技企业</div>
                </div>
                <div class="infobd">
                  <strong class="fc-blue">09:00-09:30 </strong>
                  蚂蚁金服文化及科技体验：蚂蚁金服杭州总部Z空间<br />
                  <strong class="fc-blue">09:30-12:00</strong>
                  培训课堂:《数字资产与新一代数字金融体系》<br />
                  <strong class="fc-blue">12:00-13:30</strong>
                  午餐（蚂蚁金服员工餐厅）<br />
                  <strong class="fc-blue">13:30-17:00</strong>
                  培训课堂:《科技成就普惠，数字创新金融》<br />
                  (注：多课程可选，可定制，1-3天)
                </div>
              </div>
            </li>
            <li class="swiper-slide">
              <div class="img">
                <img src="../../../assets/images/img41.png" />
              </div>
              <div class="info">
                <div class="infohd">
                  <h3>吉利</h3>
                  <h4>行业：制造企业</h4>
                  <div class="txt">世界汽车企业前十强</div>
                </div>
                <div class="infobd">
                  <strong class="fc-blue">09:00-09:30 </strong>
                  参观吉利汽车体验馆<br />
                  <strong class="fc-blue">09:30-10:20</strong>
                  参观沃尔沃博物馆<br />
                  <strong class="fc-blue">10:30-11:40</strong>
                  主题分享《吉利集团的企业文化》<br />
                  <strong class="fc-blue">11:50-14:00</strong> 午餐、午休<br />
                  <strong class="fc-blue">14:30-15:00</strong>
                  主题分享《探索吉利的发展史》
                </div>
              </div>
            </li>
            <li class="swiper-slide">
              <div class="img">
                <img src="../../../assets/images/img42.png" />
              </div>
              <div class="info">
                <div class="infohd">
                  <h3>华为</h3>
                  <h4>行业：制造企业</h4>
                  <div class="txt">构建万物互联的智能世界</div>
                </div>
                <div class="infobd">
                  <strong class="fc-blue">09:30-11:30 </strong>
                  参观华为总部展厅/天鹅湖/华为大学<strong class="fc-blue"
                    >11:40-14:00</strong
                  >
                  华为自助午餐/休息
                  <br />
                  <strong class="fc-blue">14:00-17:00</strong>
                  主题分享：华为以客户为中心的文化与管理
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="swiper-button-prev dbfx-prev"></div>
        <div class="swiper-button-next dbfx-next"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "@/libs/js/swiper.min";
export default {
  data() {
    return {
      pictureList: [],
      list: [],
    };
  },
  mounted() {
    new Swiper(".dbfx-list .swiper-container", {
      slidesPerView: 4,
      spaceBetween: 25,
      loop: true,
      autoplayDisableOnInteraction: false,
      autoplay: 4000,
      nextButton: ".dbfx-next",
      prevButton: ".dbfx-prev",
      breakpoints: {
        1000: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
      },
    });
  },
  methods: {},
};
</script>
<style scoped lang="scss"></style>
